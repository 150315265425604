       .blue { color: #0000ff }
       .xslt { color: #116611 }
       .cc { color: #116611 }
       .html { color: #882222 }
       .xml { color: #116611 }
       .red { color: #ff0000 }
       .orange { color: #ff8800 }
       .pink { color: #ff00ff }
       .grey { color: #555555 }
       .hidden { color: #ffffff }
       pre { color: #116611 }
       tt { color: #116611 }